import { GetSecretValueCommand, SecretsManagerClient } from '@aws-sdk/client-secrets-manager'
import NodeCache from 'node-cache'
import invariant from 'tiny-invariant'
import { getEnvironment } from './environment'
import { EXTERNAL_SECRETS, SECRETS } from '@bodycoach/lib/infra/utilities/constants'
import { SecretValue } from 'aws-cdk-lib'

/**
 * Replaces instances of `Target` with `Replacement` in the given `Obj`.
 *
 * e.g. to replace `string` with `number`:
 *
 * ```
 * ReplaceDeep<{ foo: string }, string, number> // { foo: number }
 * ```
 */
type ReplaceDeep<Obj, Target, Replacement> = {
  [K in keyof Obj]: Obj[K] extends Target
    ? Replacement
    : Obj[K] extends object
    ? ReplaceDeep<Obj[K], Target, Replacement>
    : Obj[K]
}

/**
 * Internally generated secret properties are created using
 * `SecretValue.unsafePlainText()` resulting in a `SecretValue` type. We need to
 * re-map these to `string`s for use in these types since that's what the output
 * will be when retrieving the secret.
 */
type AllSecrets = typeof EXTERNAL_SECRETS & ReplaceDeep<typeof SECRETS, SecretValue, string>

const secretsCache = new NodeCache()

/**
 * Gets a secret from secrets manager.
 *
 * @param name - Name of the secret in Secrets Manager
 * @param json - Whether the secret value is JSON. If this is set to `true` the value will be parsed before being returned. Defaults to `true`
 * @param ttl - Lifetime of the secret in the cache in seconds. Defaults to 30 minutes
 */
export async function getSecret<Name extends keyof AllSecrets>(
  name: Name,
  env: string | undefined = process.env.NODE_ENV,
  json: boolean = true,
  ttl: number = 1800
) {
  invariant(env, 'Unable to get secret: no environment provided')

  const nameWithEnv = `${name}/${getEnvironment(env, true)}`
  let secret: AllSecrets[Name] | undefined

  if (secretsCache.has(nameWithEnv)) {
    secret = await secretsCache.get(nameWithEnv)
  } else {
    const client = new SecretsManagerClient({})
    const command = new GetSecretValueCommand({ SecretId: nameWithEnv })
    const response = await client.send(command)

    secret = response.SecretString ? (json ? JSON.parse(response.SecretString) : response.SecretString) : undefined
  }

  invariant(secret, `Unable to find secret ${nameWithEnv}`)

  secretsCache.set(nameWithEnv, secret, ttl)

  return secret
}
